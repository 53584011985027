import React from 'react';

import { As, ResponsiveValue, Text, TextDecorationProps } from '@chakra-ui/react';

type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl' | '6xl';
type Transform = 'none' | 'uppercase' | 'lowercase' | 'capitalize';

interface CustomTextProps {
    size?: Size;
    weight?: number;
    color: string;
    paddingLeft?: number;
    paddingRight?: number;
    children: React.ReactNode;
    textAlign?: ResponsiveValue<CanvasTextAlign> | undefined | 'justify';
    cursor?: string;
    lineHeight?: number;
    textTransform?: Transform;
    dataTestid?: string;
    classes?: string;
    marginTop?: number;
    maxWidth?: number | string;
    noOfLines?: number;
    textDecoration?: TextDecorationProps['textDecoration'];
    as?: As;
}

const CustomText = ({
    size = 'sm',
    weight = 400,
    paddingLeft = 0,
    paddingRight,
    color,
    children,
    textAlign = 'left',
    cursor,
    lineHeight,
    textTransform,
    dataTestid,
    classes,
    marginTop,
    maxWidth,
    noOfLines,
    textDecoration,
    as
}: CustomTextProps) => (
    <Text
        fontSize={size}
        color={color}
        fontWeight={weight}
        pl={paddingLeft}
        pr={paddingRight}
        textAlign={textAlign}
        cursor={cursor}
        lineHeight={lineHeight}
        textTransform={textTransform}
        data-testid={dataTestid}
        className={classes}
        mt={marginTop}
        maxW={maxWidth}
        noOfLines={noOfLines}
        textDecoration={textDecoration}
        as={as}
    >
        {children}
    </Text>
);

export default CustomText;
