import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import authReducer from '@modules/auth/lib/authSlice';
import forgotPasswordReducer from '@modules/auth/lib/forgotPasswordSlice';
import agoraRuducer from '@modules/bookings/slice/index';
import bookingSuccessData from '@modules/find-mentor/slice/bookingSuccessSlice';
import mentorFiltersReducer from '@modules/find-mentor/slice/index';
import profilePictureDeletionReducer from '@modules/profile/slice/index';
import apiReducer from '@slices/apiSlice';
import { baseApi } from '@store/baseQuery';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth', 'agora'],
};

const rootReducer = combineReducers({
    auth: authReducer,
    forgotPassword: forgotPasswordReducer,
    api: apiReducer,
    mentorFilters: mentorFiltersReducer,
    agora: agoraRuducer,
    profilePictureDeletion: profilePictureDeletionReducer,
    bookingSuccessData,
});

const reducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: { reducer, [baseApi.reducerPath]: baseApi.reducer },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }).concat([baseApi.middleware]),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
