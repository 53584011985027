import mixpanel from 'mixpanel-browser';

import { AnalyticsClient } from '../types/client';

class MixpanelClient implements AnalyticsClient {
    // eslint-disable-next-line class-methods-use-this
    init(): void {
        mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_PROJECT_TOKEN!, {
            persistence: 'localStorage',
        });
    }

    // eslint-disable-next-line class-methods-use-this
    trackPageView(): void {
        mixpanel.track_pageview();
    }
}

export { MixpanelClient };
