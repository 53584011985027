'use client';

import React from 'react';

import { Divider, Flex, Text, useBreakpointValue, VStack } from '@chakra-ui/react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/navigation';

// import FbIcon from '@assets/FbIcon.svg';
import IgIcon from '@assets/IgIcon.svg';
import LinkedInIcon from '@assets/LinkedInIcon.svg';
import Logo from '@assets/Logo2.svg';
import XIcon from '@assets/XIcon.svg';
import { CustomText } from '@components/Custom-text';
import NavItem from '@components/nav-section/horizontal/NavItem';
import SocialPlatformIcon from '@components/nav-section/horizontal/SocialPlatformIcon';
import { UserType } from '@enums/index';
import { useAppDispatch, useAppSelector } from '@hooks/store';
import { setLogout } from '@modules/auth/lib/authSlice';
import { paths } from '@routes/path';

const Footer = () => {
    const isMobile = useBreakpointValue({ base: true, sm: false });
    const { isAuthenticated, user } = useAppSelector(state => state.reducer.auth);
    const dispatch = useAppDispatch();
    const router = useRouter();

    function onClick() {
        if (isAuthenticated && user?.userType === UserType.Mentee) {
            dispatch(setLogout());
        }
        router.replace(paths.mentor.auth.signup);
    }
    return (
        <>
            <Flex
                justify={{ base: 'center', sm: 'center', md: 'flex-start' }}
                align={{ base: 'center' }}
                height="100%"
                px={{ base: 5, sm: 5, md: 20 }}
                py={{ base: 5, sm: 5, md: 10 }}
                bgColor="headerBg"
                flexDir={{ base: 'column', sm: 'row', md: 'row' }}
                gap={{ base: 4, sm: 4, md: 0 }}
            >
                <Flex
                    align={{ base: 'center', sm: 'flex-start', md: 'flex-start' }}
                    width={{ base: '100%', sm: '100%', md: 300 }}
                    gap={2}
                    flexDir="column"
                >
                    <Link href={paths.general.home}>
                        <Image src={Logo} alt="logo" />
                    </Link>
                    <Flex gap={2} pl={{ base: 4 }}>
                        {/* <SocialPlatformIcon
                            src={FbIcon}
                            alt="Facebook"
                            href="https://facebook.com"
                        /> */}
                        <SocialPlatformIcon
                            src={IgIcon}
                            alt="Instagram"
                            href="https://instagram.com/youryoda_"
                        />
                        <SocialPlatformIcon src={XIcon} alt="X" href="https://x.com/theyouryoda" />
                        <SocialPlatformIcon
                            src={LinkedInIcon}
                            alt="LinkedIn"
                            href="https://www.linkedin.com/company/your-yoda"
                        />
                    </Flex>
                </Flex>
                <Flex flexDir="column" gap={2}>
                    <Flex
                        gap={{ base: 2, sm: 2, md: 4, lg: 6 }}
                        paddingRight={{ '2xl': 5 }}
                        paddingLeft={{ '2xl': 5 }}
                        flexWrap="wrap"
                        flexDir={{ base: 'column', sm: 'row', md: 'row' }}
                        align={{ base: 'center', sm: 'center', md: 'flex-start' }}
                    >
                        <NavItem title="Find mentors" path={paths.general.findMentor} />

                        <Text
                            onClick={() => onClick()}
                            cursor="pointer"
                            color="mainText"
                            fontSize="sm"
                        >
                            Become a Mentor
                        </Text>

                        <NavItem title="Privacy Policy" path={paths.general.privacy} />
                        <NavItem
                            title="Terms & Conditions"
                            path={paths.general.termsandconditions}
                        />
                    </Flex>
                </Flex>
                <Flex flexDir="column" gap={2}>
                    <VStack maxW={600} alignItems={{ base: 'center', sm: 'flex-start' }}>
                        <CustomText color="primary.dark" weight={500}>
                            About Us
                        </CustomText>
                        <CustomText color="mainText" textAlign={isMobile ? 'center' : 'left'}>
                            We are a small team hell bent in bridging the gap between raw talent and
                            what it takes to propel that raw talent into its rightful place. We
                            believe the right guidance and mentorship should be accessible to anyone
                            anywhere.
                        </CustomText>
                    </VStack>
                </Flex>
            </Flex>
            <Flex px={20} width="100%">
                <Divider orientation="horizontal" colorScheme="gray" />
            </Flex>
            <Flex
                bgColor="headerBg"
                width="100%"
                py={5}
                justifyContent="center"
                alignItems="center"
            >
                <CustomText color="mainText" size="md">
                    © YourYoda, All rights reserved.
                </CustomText>
            </Flex>
        </>
    );
};

export default Footer;
